/* BASICS */
.CodeMirror {
  /* Set height, width, borders, and global font properties here */
  font-family: monospace;
  height: 300px;
}

.CodeMirror-scroll {
  /* Set scrolling behaviour here */
  overflow: auto;
}

/* PADDING */
.CodeMirror-lines {
  padding: 4px 0;
  /* Vertical padding around content */
}

.CodeMirror pre {
  padding: 0 4px;
  /* Horizontal padding of content */
}

.CodeMirror-scrollbar-filler {
  background-color: #FFFFFF;
  /* The little square between H and V scrollbars */
}

/* GUTTER */
.CodeMirror-gutters {
  border-right: 1px solid #A7A9AC;
  background-color: #000000;
}

.CodeMirror-linenumber {
  padding: 0 3px 0 5px;
  min-width: 20px;
  text-align: right;
  color: #696969;
}

/* CURSOR */
.CodeMirror div.CodeMirror-cursor {
  border-left: 1px solid #000000;
  z-index: 3;
}

/* Shown when moving in bi-directional text */
.CodeMirror div.CodeMirror-secondarycursor {
  border-left: 1px solid #A7A9AC;
}

.CodeMirror.cm-keymap-fat-cursor div.CodeMirror-cursor {
  width: auto;
  border: 0;
  background: #05A995;
  z-index: 1;
}

/* Can style cursor different in overwrite (non-insert) mode */
.cm-tab {
  display: inline-block;
}

/* DEFAULT THEME */
.cm-s-default .cm-keyword {
  color: #E54280;
}

.cm-s-default .cm-atom {
  color: #005E8F;
}

.cm-s-default .cm-number {
  color: #05A995;
}

.cm-s-default .cm-def {
  color: #297FA4;
}

.cm-s-default .cm-variable {
  color: black;
}

.cm-s-default .cm-variable-2 {
  color: #005E8F;
}

.cm-s-default .cm-variable-3 {
  color: #05A995;
}

.cm-s-default .cm-property {
  color: black;
}

.cm-s-default .cm-operator {
  color: black;
}

.cm-s-default .cm-comment {
  color: #F2A0BF;
}

.cm-s-default .cm-string {
  color: #05A995;
}

.cm-s-default .cm-string-2 {
  color: #DBE956;
}

.cm-s-default .cm-meta {
  color: #696969;
}

.cm-s-default .cm-error {
  color: #BD0A26;
}

.cm-s-default .cm-qualifier {
  color: #696969;
}

.cm-s-default .cm-builtin {
  color: #005E8F;
}

.cm-s-default .cm-bracket {
  color: #A7A9AC;
}

.cm-s-default .cm-tag {
  color: #38BD0C;
}

.cm-s-default .cm-attribute {
  color: #005E8F;
}

.cm-s-default .cm-header {
  color: #297FA4;
}

.cm-s-default .cm-quote {
  color: #05A995;
}

.cm-s-default .cm-hr {
  color: #A7A9AC;
}

.cm-s-default .cm-link {
  color: #005E8F;
}

.cm-negative {
  color: #BD0A26;
}

.cm-positive {
  color: #38BD0C;
}

.cm-header, .cm-strong {
  font-weight: bold;
}

.cm-em {
  font-style: italic;
}

.cm-link {
  text-decoration: underline;
}

.cm-invalidchar {
  color: #BD0A26;
}

div.CodeMirror span.CodeMirror-matchingbracket {
  color: #05A995;
}

div.CodeMirror span.CodeMirror-nonmatchingbracket {
  color: #BD0A26;
}

/* STOP */
/* The rest of this file contains styles related to the mechanics of
   the editor. You probably shouldn't touch them. */
.CodeMirror {
  line-height: 1;
  position: relative;
  overflow: hidden;
  background: #FFFFFF;
  color: #000000;
}

.CodeMirror-scroll {
  /* 30px is the magic margin used to hide the element's real scrollbars */
  /* See overflow: hidden in .CodeMirror */
  margin-bottom: -30px;
  margin-right: -30px;
  padding-bottom: 30px;
  padding-right: 30px;
  height: 100%;
  outline: none;
  /* Prevent dragging from highlighting the element */
  position: relative;
}

.CodeMirror-sizer {
  position: relative;
}

/* The fake, visible scrollbars. Used to force redraw during scrolling
   before actuall scrolling happens, thus preventing shaking and
   flickering artifacts. */
.CodeMirror-vscrollbar, .CodeMirror-hscrollbar, .CodeMirror-scrollbar-filler {
  position: absolute;
  z-index: 6;
  display: none;
}

.CodeMirror-vscrollbar {
  right: 0;
  top: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

.CodeMirror-hscrollbar {
  bottom: 0;
  left: 0;
  overflow-y: hidden;
  overflow-x: scroll;
}

.CodeMirror-scrollbar-filler {
  right: 0;
  bottom: 0;
  z-index: 6;
}

.CodeMirror-gutters {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  padding-bottom: 30px;
  z-index: 3;
}

.CodeMirror-gutter {
  height: 100%;
  padding-bottom: 30px;
  margin-bottom: -32px;
  display: inline-block;
  /* Hack to make IE7 behave */
  *zoom: 1;
  *display: inline;
}

.CodeMirror-gutter-elt {
  position: absolute;
  cursor: default;
  z-index: 4;
}

.CodeMirror-lines {
  cursor: text;
}

.CodeMirror pre {
  /* Reset some styles that the rest of the page might have set */
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  border-width: 0;
  background: transparent;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  white-space: pre;
  word-wrap: normal;
  line-height: inherit;
  color: inherit;
  z-index: 2;
  position: relative;
  overflow: visible;
}

.CodeMirror-wrap pre {
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: normal;
}

.CodeMirror-linebackground {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
}

.CodeMirror-linewidget {
  position: relative;
  z-index: 2;
  overflow: auto;
}

.CodeMirror-widget {
  display: inline-block;
}

.CodeMirror-wrap .CodeMirror-scroll {
  overflow-x: hidden;
}

.CodeMirror-measure {
  position: absolute;
  width: 100%;
  height: 0px;
  overflow: hidden;
  visibility: hidden;
}

.CodeMirror-measure pre {
  position: static;
}

.CodeMirror div.CodeMirror-cursor {
  position: absolute;
  visibility: hidden;
  border-right: none;
  width: 0;
}

.CodeMirror-focused div.CodeMirror-cursor {
  visibility: visible;
}

.CodeMirror-selected {
  background: #000;
}

.CodeMirror-focused .CodeMirror-selected {
  background: #000;
}

.cm-searching {
  background: #F5A024;
}

/* IE7 hack to prevent it from returning funny offsetTops on the spans */
.CodeMirror span {
  *vertical-align: text-bottom;
}

@media print {
  /* Hide the cursor when printing */
  .CodeMirror div.CodeMirror-cursor {
    visibility: hidden;
  }
}

/**
 * prism.js tomorrow night eighties for JavaScript, CoffeeScript, CSS and HTML
 * Custom dark theme based on accs color pallet.
 * @author Rose Pritchard
 */
code[class*="language-"],
pre[class*="language-"] {
  color: #FFFFFF;
  background: none;
  font-family: Consolas, Monaco, "Andale Mono", monospace;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

/* Code blocks */
pre[class*="language-"] {
  padding: 1em;
  margin: .5em 0;
  overflow: auto;
}

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
  background: #696969;
}

/* Inline code */
:not(pre) > code[class*="language-"] {
  padding: .1em;
  border-radius: .3em;
  white-space: normal;
}

.token.comment,
.token.block-comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: #EAEAEA;
}

.token.punctuation {
  color: #A7A9AC;
}

.token.tag,
.token.attr-name,
.token.namespace,
.token.deleted {
  color: #E54280;
}

.token.function-name {
  color: #004569;
}

.token.boolean,
.token.number,
.token.function {
  color: #DBE956;
}

.token.property,
.token.class-name,
.token.constant,
.token.symbol {
  color: #F5A024;
}

.token.selector,
.token.important,
.token.atrule,
.token.keyword,
.token.builtin {
  color: #F2A0BF;
}

.token.string,
.token.char,
.token.attr-value,
.token.regex,
.token.variable {
  color: #05A995;
}

.token.operator,
.token.entity,
.token.url {
  color: #38BD0C;
}

.token.important,
.token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

.token.inserted {
  color: green;
}

#supporting-docs {
  display: none;
}

#supporting-docs .content__section-1,
#supporting-docs .content__section-2 {
  width: 100%;
}

#supporting-docs .field-type-entityreference .content__section-1 {
  display: none;
}

body.node-type-smart-method .main-container {
  padding: 0 0 60px;
  display: flex;
}

body.node-type-smart-method #block-system-main {
  margin: 0;
}

body.node-type-smart-method .page-title {
  margin: 0;
  padding: 58px 30px 0;
  background-color: #F9FBFC;
}

@media screen and (max-width: 1024px) {
  body.node-type-smart-method .page-title {
    padding: 30px 30px 15px;
  }
}

body.node-type-smart-method #main-content_smartdoc-method {
  width: 100%;
}

body.node-type-smart-method #acc-method_content #method_content,
body.node-type-smart-method #acc-method_content #index_content {
  border-right: 0;
  padding: 0;
  font-size: 16px;
  line-height: 1.5em;
  margin-top: 0;
  display: block;
}

body.node-type-smart-method #acc-method_content #method_content *,
body.node-type-smart-method #acc-method_content #index_content * {
  border-width: 0;
  margin: 0;
  padding: 0;
  text-shadow: none;
}

body.node-type-smart-method #acc-method_content #method_content [data-role='error_container'],
body.node-type-smart-method #acc-method_content #method_content .modal .error_container,
body.node-type-smart-method #acc-method_content #index_content [data-role='error_container'],
body.node-type-smart-method #acc-method_content #index_content .modal .error_container {
  background-color: #BD0A26;
  background-image: none;
  border: 2px solid #BD0A26;
  border-radius: 0;
  color: #FFFFFF;
  display: none;
  font-size: 16px;
  line-height: inherit;
  margin: 20px 0;
  padding: 20px;
}

body.node-type-smart-method #acc-method_content #method_content [data-role='error_container'],
body.node-type-smart-method #acc-method_content #index_content [data-role='error_container'] {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  height: 69px;
  z-index: 1000;
}

body.node-type-smart-method #acc-method_content #method_content [data-role='error_container']:empty,
body.node-type-smart-method #acc-method_content #index_content [data-role='error_container']:empty {
  display: none;
}

body.node-type-smart-method #acc-method_content #method_content .docs,
body.node-type-smart-method #acc-method_content #index_content .docs {
  white-space: normal;
}

body.node-type-smart-method #acc-method_content #method_content pre, body.node-type-smart-method #acc-method_content #method_content code,
body.node-type-smart-method #acc-method_content #index_content pre,
body.node-type-smart-method #acc-method_content #index_content code {
  font-size: 14px;
  border-radius: 0;
  border: 0;
  font-weight: 400;
  line-height: 1.5em;
}

body.node-type-smart-method #acc-method_content #method_content .smartdocs-row,
body.node-type-smart-method #acc-method_content #index_content .smartdocs-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
  margin: auto;
  justify-content: space-between;
}

@media screen and (max-width: 1024px) {
  body.node-type-smart-method #acc-method_content #method_content .smartdocs-row,
  body.node-type-smart-method #acc-method_content #index_content .smartdocs-row {
    flex-direction: column;
  }
}

body.node-type-smart-method #acc-method_content #method_content .smartdocs-column,
body.node-type-smart-method #acc-method_content #index_content .smartdocs-column {
  flex: 1;
}

body.node-type-smart-method #acc-method_content #method_content .row-resource-details-summary,
body.node-type-smart-method #acc-method_content #index_content .row-resource-details-summary {
  margin: 0 -10px;
}

@media screen and (max-width: 1024px) {
  body.node-type-smart-method #acc-method_content #method_content .row-resource-details-summary,
  body.node-type-smart-method #acc-method_content #index_content .row-resource-details-summary {
    margin: 0 -10px 20px;
  }
}

body.node-type-smart-method #acc-method_content #method_content .row-resource-details-summary p,
body.node-type-smart-method #acc-method_content #index_content .row-resource-details-summary p {
  font-size: 16px;
  line-height: 24px;
}

body.node-type-smart-method #acc-method_content #method_content .row-resource-details-summary p.title,
body.node-type-smart-method #acc-method_content #index_content .row-resource-details-summary p.title {
  color: #696969;
}

body.node-type-smart-method #acc-method_content #method_content .row-resource-details-summary p.data,
body.node-type-smart-method #acc-method_content #index_content .row-resource-details-summary p.data {
  font-size: 14px;
}

body.node-type-smart-method #acc-method_content #method_content .row-resource-details-summary .smartdocs-column,
body.node-type-smart-method #acc-method_content #index_content .row-resource-details-summary .smartdocs-column {
  flex: 1;
  margin: 0 10px;
  background-color: transparent;
}

body.node-type-smart-method #acc-method_content #method_content .row-resource-details-summary #resource-details,
body.node-type-smart-method #acc-method_content #index_content .row-resource-details-summary #resource-details {
  padding: 0;
}

body.node-type-smart-method #acc-method_content #method_content .row-resource-details-summary #resource-summary,
body.node-type-smart-method #acc-method_content #index_content .row-resource-details-summary #resource-summary {
  padding: 0;
}

body.node-type-smart-method #acc-method_content #method_content .row-resource-details-summary #resource-summary .table-resource-summary td,
body.node-type-smart-method #acc-method_content #index_content .row-resource-details-summary #resource-summary .table-resource-summary td {
  min-width: 80px;
}

body.node-type-smart-method #acc-method_content #method_content .row-resource-details-summary #resource-documents #supporting-docs,
body.node-type-smart-method #acc-method_content #index_content .row-resource-details-summary #resource-documents #supporting-docs {
  display: block;
}

body.node-type-smart-method #acc-method_content #method_content .smartdocs-content-inner,
body.node-type-smart-method #acc-method_content #index_content .smartdocs-content-inner {
  padding: 30px 30px 0;
}

@media screen and (max-width: 1024px) {
  body.node-type-smart-method #acc-method_content #method_content .smartdocs-content-inner,
  body.node-type-smart-method #acc-method_content #index_content .smartdocs-content-inner {
    padding: 0 30px;
  }
}

body.node-type-smart-method #acc-method_content #method_content .row-smartdocs-header,
body.node-type-smart-method #acc-method_content #index_content .row-smartdocs-header {
  background-color: #F9FBFC;
}

body.node-type-smart-method #acc-method_content #method_content .row-smartdocs-content input,
body.node-type-smart-method #acc-method_content #index_content .row-smartdocs-content input {
  border: 1px solid #A7A9AC;
  padding: 6px 10px;
  margin: 4px 0;
}

body.node-type-smart-method #acc-method_content #method_content .row-smartdocs-content table th, body.node-type-smart-method #acc-method_content #method_content .row-smartdocs-content table td,
body.node-type-smart-method #acc-method_content #index_content .row-smartdocs-content table th,
body.node-type-smart-method #acc-method_content #index_content .row-smartdocs-content table td {
  padding: 20px 0;
}

body.node-type-smart-method #acc-method_content #method_content .row-smartdocs-content table th.name, body.node-type-smart-method #acc-method_content #method_content .row-smartdocs-content table td.name,
body.node-type-smart-method #acc-method_content #index_content .row-smartdocs-content table th.name,
body.node-type-smart-method #acc-method_content #index_content .row-smartdocs-content table td.name {
  width: 180px;
}

body.node-type-smart-method #acc-method_content #method_content .row-smartdocs-content table th.values, body.node-type-smart-method #acc-method_content #method_content .row-smartdocs-content table td.values,
body.node-type-smart-method #acc-method_content #index_content .row-smartdocs-content table th.values,
body.node-type-smart-method #acc-method_content #index_content .row-smartdocs-content table td.values {
  padding: 20px;
  width: 120px;
}

body.node-type-smart-method #acc-method_content #method_content .row-smartdocs-content table th,
body.node-type-smart-method #acc-method_content #index_content .row-smartdocs-content table th {
  font-weight: 700;
  vertical-align: middle;
  border-bottom: 1px solid #A7A9AC;
}

body.node-type-smart-method #acc-method_content #method_content .row-smartdocs-content table td,
body.node-type-smart-method #acc-method_content #index_content .row-smartdocs-content table td {
  border-bottom: 1px solid #A7A9AC;
  vertical-align: middle;
}

body.node-type-smart-method #acc-method_content #method_content .row-smartdocs-content table tr:last-child td,
body.node-type-smart-method #acc-method_content #index_content .row-smartdocs-content table tr:last-child td {
  border-bottom: 1px solid #A7A9AC;
}

body.node-type-smart-method #acc-method_content #method_content .row-smartdocs-content ul.method_table,
body.node-type-smart-method #acc-method_content #index_content .row-smartdocs-content ul.method_table {
  color: #000000;
}

body.node-type-smart-method #acc-method_content #method_content .row-smartdocs-content ul.method_table li,
body.node-type-smart-method #acc-method_content #index_content .row-smartdocs-content ul.method_table li {
  border-bottom: 1px solid #A7A9AC;
  list-style: none;
}

body.node-type-smart-method #acc-method_content #method_content .row-smartdocs-content ul.method_table li:hover,
body.node-type-smart-method #acc-method_content #index_content .row-smartdocs-content ul.method_table li:hover {
  background-color: transparent;
  border-radius: 0;
}

body.node-type-smart-method #acc-method_content #method_content .row-smartdocs-content ul.method_table li.method_title div,
body.node-type-smart-method #acc-method_content #index_content .row-smartdocs-content ul.method_table li.method_title div {
  font-weight: 700;
}

body.node-type-smart-method #acc-method_content #method_content .row-smartdocs-content ul.method_table li.method_title,
body.node-type-smart-method #acc-method_content #method_content .row-smartdocs-content ul.method_table li.method_details,
body.node-type-smart-method #acc-method_content #index_content .row-smartdocs-content ul.method_table li.method_title,
body.node-type-smart-method #acc-method_content #index_content .row-smartdocs-content ul.method_table li.method_details {
  display: flex;
}

body.node-type-smart-method #acc-method_content #method_content .row-smartdocs-content ul.method_table li.method_title div,
body.node-type-smart-method #acc-method_content #method_content .row-smartdocs-content ul.method_table li.method_details div,
body.node-type-smart-method #acc-method_content #index_content .row-smartdocs-content ul.method_table li.method_title div,
body.node-type-smart-method #acc-method_content #index_content .row-smartdocs-content ul.method_table li.method_details div {
  padding: 15px 0;
}

body.node-type-smart-method #acc-method_content #method_content .row-smartdocs-content ul.method_table li div p, body.node-type-smart-method #acc-method_content #method_content .row-smartdocs-content ul.method_table li div span,
body.node-type-smart-method #acc-method_content #index_content .row-smartdocs-content ul.method_table li div p,
body.node-type-smart-method #acc-method_content #index_content .row-smartdocs-content ul.method_table li div span {
  line-height: 1.5em;
}

body.node-type-smart-method #acc-method_content #method_content .smartdocs-content-response,
body.node-type-smart-method #acc-method_content #index_content .smartdocs-content-response {
  overflow: hidden;
}

body.node-type-smart-method #acc-method_content #method_content .smartdocs-content-response .smartdocs-content-inner,
body.node-type-smart-method #acc-method_content #index_content .smartdocs-content-response .smartdocs-content-inner {
  height: 100%;
}

body.node-type-smart-method #acc-method_content #method_content .row-smartdocs-operations .smartdocs-content-inner,
body.node-type-smart-method #acc-method_content #index_content .row-smartdocs-operations .smartdocs-content-inner {
  padding: 60px 30px;
}

body.node-type-smart-method #acc-method_content #method_content .row-smartdocs-footer,
body.node-type-smart-method #acc-method_content #index_content .row-smartdocs-footer {
  background-color: #A7A9AC;
  padding: 45px 0;
}

body.node-type-smart-method #acc-method_content #method_content .row-smartdocs-footer .smartdocs-content-inner,
body.node-type-smart-method #acc-method_content #index_content .row-smartdocs-footer .smartdocs-content-inner {
  max-width: 67%;
  margin: 0 auto;
}

body.node-type-smart-method #acc-method_content #method_content .title_container,
body.node-type-smart-method #acc-method_content #index_content .title_container {
  display: flex;
  flex-direction: column;
}

body.node-type-smart-method #acc-method_content #method_content .title_container #api-spec-file,
body.node-type-smart-method #acc-method_content #index_content .title_container #api-spec-file {
  order: 2;
  margin: 30px 0;
}

body.node-type-smart-method #acc-method_content #method_content .title_container h2,
body.node-type-smart-method #acc-method_content #index_content .title_container h2 {
  font-size: 24px;
  line-height: 27px;
  margin: 0 0 0 100px;
  float: none;
  font-weight: 400;
}

body.node-type-smart-method #acc-method_content #method_content h3,
body.node-type-smart-method #acc-method_content #index_content h3 {
  margin: 30px 0 10px;
  padding-bottom: 10px;
  color: #000000;
  font-size: 16px;
  line-height: 1.5em;
  border-bottom: 1px solid #A7A9AC;
  width: 100%;
}

body.node-type-smart-method #acc-method_content #method_content .row-smartdocs-content h3,
body.node-type-smart-method #acc-method_content #index_content .row-smartdocs-content h3 {
  margin-bottom: 0;
}

body.node-type-smart-method #acc-method_content #method_content .verb,
body.node-type-smart-method #acc-method_content #index_content .verb {
  border-width: 2px;
  font-size: 18px;
  line-height: 18px;
  margin: 1px 0 0;
  padding: 4px;
}

body.node-type-smart-method #acc-method_content #method_content .icon_lock,
body.node-type-smart-method #acc-method_content #index_content .icon_lock {
  margin: 11px 4px 4px 12px;
}

body.node-type-smart-method #acc-method_content #method_content .resource_description,
body.node-type-smart-method #acc-method_content #index_content .resource_description {
  margin: 0;
  padding-bottom: 30px;
  min-height: 16px;
  font-size: 16px;
  color: #000000;
}

body.node-type-smart-method #acc-method_content #method_content .resource_description ul, body.node-type-smart-method #acc-method_content #method_content .resource_description ol,
body.node-type-smart-method #acc-method_content #index_content .resource_description ul,
body.node-type-smart-method #acc-method_content #index_content .resource_description ol {
  padding-left: 30px;
  margin: 10px 0;
  line-height: 1.75;
}

body.node-type-smart-method #acc-method_content #method_content .resource_description ul ul, body.node-type-smart-method #acc-method_content #method_content .resource_description ul ol, body.node-type-smart-method #acc-method_content #method_content .resource_description ol ul, body.node-type-smart-method #acc-method_content #method_content .resource_description ol ol,
body.node-type-smart-method #acc-method_content #index_content .resource_description ul ul,
body.node-type-smart-method #acc-method_content #index_content .resource_description ul ol,
body.node-type-smart-method #acc-method_content #index_content .resource_description ol ul,
body.node-type-smart-method #acc-method_content #index_content .resource_description ol ol {
  margin: 0;
}

body.node-type-smart-method #acc-method_content #method_content .request_response_tabs,
body.node-type-smart-method #acc-method_content #index_content .request_response_tabs {
  margin-top: 36px;
}

@media screen and (max-width: 1024px) {
  body.node-type-smart-method #acc-method_content #method_content .request_response_tabs,
  body.node-type-smart-method #acc-method_content #index_content .request_response_tabs {
    margin-top: 0;
  }
}

body.node-type-smart-method #acc-method_content #method_content .request_response_tabs a,
body.node-type-smart-method #acc-method_content #index_content .request_response_tabs a {
  padding: 15px 30px;
  text-decoration: none;
  background-color: #696969;
  color: #FFFFFF;
  font-size: 16px;
  line-height: 16px;
  border-radius: 0;
}

@media screen and (max-width: 1024px) {
  body.node-type-smart-method #acc-method_content #method_content .request_response_tabs a,
  body.node-type-smart-method #acc-method_content #index_content .request_response_tabs a {
    padding: 15px 10px;
  }
}

body.node-type-smart-method #acc-method_content #method_content .request_response_tabs a:hover, body.node-type-smart-method #acc-method_content #method_content .request_response_tabs a.selected,
body.node-type-smart-method #acc-method_content #index_content .request_response_tabs a:hover,
body.node-type-smart-method #acc-method_content #index_content .request_response_tabs a.selected {
  background-color: #000000;
  color: #FFFFFF;
  border-radius: 0;
}

body.node-type-smart-method #acc-method_content #method_content #request_response_container,
body.node-type-smart-method #acc-method_content #index_content #request_response_container {
  background-color: #000000;
  padding: 30px;
  min-height: 705px;
  clear: both;
  border-radius: 0;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.25);
  position: relative;
}

body.node-type-smart-method #acc-method_content #method_content #request_response_container .response,
body.node-type-smart-method #acc-method_content #method_content #request_response_container .resquest,
body.node-type-smart-method #acc-method_content #method_content #request_response_container .curl,
body.node-type-smart-method #acc-method_content #index_content #request_response_container .response,
body.node-type-smart-method #acc-method_content #index_content #request_response_container .resquest,
body.node-type-smart-method #acc-method_content #index_content #request_response_container .curl {
  padding-bottom: 60px;
}

body.node-type-smart-method #acc-method_content #method_content #request_response_container div strong,
body.node-type-smart-method #acc-method_content #index_content #request_response_container div strong {
  font-size: 18px;
  margin: 0;
  display: block;
  width: 100%;
  word-wrap: break-word;
  line-height: 24px;
  color: #FFFFFF;
}

body.node-type-smart-method #acc-method_content #method_content #request_response_container dl,
body.node-type-smart-method #acc-method_content #index_content #request_response_container dl {
  display: flex;
  flex-wrap: wrap;
  padding: 15px 0 45px;
}

body.node-type-smart-method #acc-method_content #method_content #request_response_container div dt,
body.node-type-smart-method #acc-method_content #index_content #request_response_container div dt {
  float: none;
  font-weight: normal;
  margin: 0;
  width: 33%;
  padding: 1px 15px 1px 0;
  color: #297FA4;
}

body.node-type-smart-method #acc-method_content #method_content #request_response_container div dd,
body.node-type-smart-method #acc-method_content #index_content #request_response_container div dd {
  float: none;
  margin: 0;
  word-wrap: break-word;
  white-space: normal;
  width: 67%;
  color: #A7A9AC;
}

body.node-type-smart-method #acc-method_content #method_content .request_payload h3,
body.node-type-smart-method #acc-method_content #index_content .request_payload h3 {
  border: 0;
  margin: 0;
}

body.node-type-smart-method #acc-method_content #method_content .request_payload .nav-pills li a,
body.node-type-smart-method #acc-method_content #index_content .request_payload .nav-pills li a {
  padding: 15px 30px;
  margin: 0 0 -1px;
  color: #000000;
  font-size: 16px;
  line-height: 16px;
  border-radius: 0;
  font-weight: 600;
  border: 1px solid #A7A9AC;
  border-right: 0;
  background-color: transparent;
}

@media screen and (max-width: 1024px) {
  body.node-type-smart-method #acc-method_content #method_content .request_payload .nav-pills li a,
  body.node-type-smart-method #acc-method_content #index_content .request_payload .nav-pills li a {
    padding: 15px 10px;
  }
}

body.node-type-smart-method #acc-method_content #method_content .request_payload .nav-pills li a.hover,
body.node-type-smart-method #acc-method_content #index_content .request_payload .nav-pills li a.hover {
  color: #000000;
  border-radius: 0;
}

body.node-type-smart-method #acc-method_content #method_content .request_payload .nav-pills li.active a,
body.node-type-smart-method #acc-method_content #index_content .request_payload .nav-pills li.active a {
  color: #000000;
  border-radius: 0;
  background-color: #FFFFFF;
  border-bottom-color: #FFFFFF;
}

body.node-type-smart-method #acc-method_content #method_content .request_payload .nav-pills li:last-child a,
body.node-type-smart-method #acc-method_content #index_content .request_payload .nav-pills li:last-child a {
  border-right: 1px solid #A7A9AC;
}

body.node-type-smart-method #acc-method_content #method_content .request_payload .request-payload-wrapper,
body.node-type-smart-method #acc-method_content #index_content .request_payload .request-payload-wrapper {
  padding: 30px;
  background-color: #FFFFFF;
  color: #000000;
  border-radius: 0;
  min-height: 795px;
  white-space: normal;
  border: 1px solid #A7A9AC;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
}

body.node-type-smart-method #acc-method_content #method_content .request_payload .request-payload-wrapper *,
body.node-type-smart-method #acc-method_content #index_content .request_payload .request-payload-wrapper * {
  background-color: #FFFFFF;
}

body.node-type-smart-method #acc-method_content #method_content .request_payload .request-payload-wrapper .docs,
body.node-type-smart-method #acc-method_content #index_content .request_payload .request-payload-wrapper .docs {
  white-space: pre-line;
  padding: 0 10px;
}

body.node-type-smart-method #acc-method_content #method_content .request_payload .request-payload-wrapper .docs .inner,
body.node-type-smart-method #acc-method_content #index_content .request_payload .request-payload-wrapper .docs .inner {
  overflow-y: scroll;
}

body.node-type-smart-method #acc-method_content #method_content .request_payload div[data-role="request-payload-example"],
body.node-type-smart-method #acc-method_content #method_content .request_payload div[data-role="request-payload-schema"],
body.node-type-smart-method #acc-method_content #index_content .request_payload div[data-role="request-payload-example"],
body.node-type-smart-method #acc-method_content #index_content .request_payload div[data-role="request-payload-schema"] {
  background-color: #FFFFFF;
}

body.node-type-smart-method #acc-method_content #method_content .request_payload textarea.payload_text_schema,
body.node-type-smart-method #acc-method_content #method_content .request_payload text-area.payload_text,
body.node-type-smart-method #acc-method_content #index_content .request_payload textarea.payload_text_schema,
body.node-type-smart-method #acc-method_content #index_content .request_payload text-area.payload_text {
  min-height: 765px;
  width: 100%;
  padding: 20px;
}

body.node-type-smart-method #acc-method_content #method_content .operation_container,
body.node-type-smart-method #acc-method_content #index_content .operation_container {
  margin: 10px 0;
}

body.node-type-smart-method #acc-method_content #method_content .operation_container .operation_description,
body.node-type-smart-method #acc-method_content #index_content .operation_container .operation_description {
  font-size: 14px;
  line-height: 1.5;
  padding: 10px 0 14px;
  border-bottom: 1px solid #A7A9AC;
}

body.node-type-smart-method #acc-method_content #method_content .operation_container .authentication,
body.node-type-smart-method #acc-method_content #index_content .operation_container .authentication {
  float: none;
}

body.node-type-smart-method #acc-method_content #method_content .operation_container .authentication .title,
body.node-type-smart-method #acc-method_content #method_content .operation_container .authentication .value,
body.node-type-smart-method #acc-method_content #method_content .operation_container .authentication .description,
body.node-type-smart-method #acc-method_content #index_content .operation_container .authentication .title,
body.node-type-smart-method #acc-method_content #index_content .operation_container .authentication .value,
body.node-type-smart-method #acc-method_content #index_content .operation_container .authentication .description {
  color: #333333;
  font-size: 16px;
}

body.node-type-smart-method #acc-method_content #method_content .operation_container .auth-container,
body.node-type-smart-method #acc-method_content #index_content .operation_container .auth-container {
  padding: 10px 0;
  border-bottom: 1px solid #A7A9AC;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

body.node-type-smart-method #acc-method_content #method_content .operation_container .auth-container a.btn,
body.node-type-smart-method #acc-method_content #index_content .operation_container .auth-container a.btn {
  margin: 5px 0;
}

body.node-type-smart-method #acc-method_content #method_content #operation-buttons,
body.node-type-smart-method #acc-method_content #index_content #operation-buttons {
  float: right;
  margin: 60px 0;
}

body.node-type-smart-method #acc-method_content #method_content #reset_request,
body.node-type-smart-method #acc-method_content #index_content #reset_request {
  margin-right: 0;
}

body.node-type-smart-method #acc-method_content #method_content .btn,
body.node-type-smart-method #acc-method_content #index_content .btn {
  margin: 0 15px 5px 0;
  font-size: 18px;
  line-height: 18px;
  padding: 14px 36px;
  border: 0;
  font-weight: 600;
  display: inline-block;
  color: #FFFFFF;
  background-color: #005E8F;
  background-image: none;
  box-shadow: none;
  border-radius: 100px;
  transition: background-color .15s,color .15s,opacity .15s,border .15s;
}

body.node-type-smart-method #acc-method_content #method_content .btn:hover, body.node-type-smart-method #acc-method_content #method_content .btn:focus,
body.node-type-smart-method #acc-method_content #index_content .btn:hover,
body.node-type-smart-method #acc-method_content #index_content .btn:focus {
  background-color: #004569;
  color: #FFFFFF;
}

body.node-type-smart-method #acc-method_content #method_content .btn:hover,
body.node-type-smart-method #acc-method_content #index_content .btn:hover {
  background-color: #004569;
}

body.node-type-smart-method #acc-method_content #method_content .btn.btn--tiny,
body.node-type-smart-method #acc-method_content #index_content .btn.btn--tiny {
  font-size: 12px;
  padding: 0 10px;
  height: 24px;
  line-height: 24px;
  text-transform: none;
  border-width: 1px;
  border-radius: 0;
}

body.node-type-smart-method #acc-method_content #method_content .btn.btn--mini, body.node-type-smart-method #acc-method_content #method_content .btn.btn--small,
body.node-type-smart-method #acc-method_content #index_content .btn.btn--mini,
body.node-type-smart-method #acc-method_content #index_content .btn.btn--small {
  font-size: 14px;
  padding: 0 20px;
  height: 34px;
  line-height: 36px;
}

body.node-type-smart-method #acc-method_content #method_content .btn.btn--mini,
body.node-type-smart-method #acc-method_content #index_content .btn.btn--mini {
  border-width: 2px;
  border-radius: 0;
}

body.node-type-smart-method #acc-method_content #method_content .btn.btn--large,
body.node-type-smart-method #acc-method_content #index_content .btn.btn--large {
  padding: 0 28px;
  height: 54px;
  font-size: 16px;
  line-height: 54px;
}

body.node-type-smart-method #acc-method_content #method_content .btn.btn--alt, body.node-type-smart-method #acc-method_content #method_content .btn.btn-default,
body.node-type-smart-method #acc-method_content #index_content .btn.btn--alt,
body.node-type-smart-method #acc-method_content #index_content .btn.btn-default {
  background-color: #f8f9fa;
  color: #212529;
  font-weight: 400;
}

body.node-type-smart-method #acc-method_content #method_content .btn.btn--alt:hover, body.node-type-smart-method #acc-method_content #method_content .btn.btn--alt:focus, body.node-type-smart-method #acc-method_content #method_content .btn.btn-default:hover, body.node-type-smart-method #acc-method_content #method_content .btn.btn-default:focus,
body.node-type-smart-method #acc-method_content #index_content .btn.btn--alt:hover,
body.node-type-smart-method #acc-method_content #index_content .btn.btn--alt:focus,
body.node-type-smart-method #acc-method_content #index_content .btn.btn-default:hover,
body.node-type-smart-method #acc-method_content #index_content .btn.btn-default:focus {
  background-color: #e2e6ea;
  color: #212529;
}

body.node-type-smart-method #acc-method_content #method_content .a.link_reset_default,
body.node-type-smart-method #acc-method_content #index_content .a.link_reset_default {
  display: inline-block;
  margin: 0;
  font-size: 16px;
}

body.node-type-smart-method #acc-method_content #method_content .CodeMirror,
body.node-type-smart-method #acc-method_content #index_content .CodeMirror {
  min-height: 150px;
}

body.node-type-smart-method #acc-method_content #method_content .CodeMirror-scroll,
body.node-type-smart-method #acc-method_content #index_content .CodeMirror-scroll {
  padding: 0;
}

body.node-type-smart-method #acc-method_content #method_content .CodeMirror-linenumber,
body.node-type-smart-method #acc-method_content #index_content .CodeMirror-linenumber {
  padding: 0 3px 0 5px;
  min-width: 20px;
  text-align: right;
  color: #696969;
  font-size: 14px;
  border-radius: 0;
  border: 0;
  font-weight: 400;
  line-height: 1.5em;
}

body.node-type-smart-method #acc-method_content #method_content .CodeMirror pre,
body.node-type-smart-method #acc-method_content #index_content .CodeMirror pre {
  margin-left: 10px;
}

.node-teaser .method_details,
.view .method_details {
  line-height: 1.5em;
  display: flex;
  border-bottom: 1px solid #A7A9AC;
  padding: 20px 0;
}

@media screen and (max-width: 1024px) {
  .node-teaser .method_details,
  .view .method_details {
    flex-direction: column;
  }
}

.node-teaser .method_details .title,
.view .method_details .title {
  float: none;
  position: relative;
  font-size: 16px;
  flex: 1;
}

@media screen and (max-width: 1024px) {
  .node-teaser .method_details .title,
  .view .method_details .title {
    width: 100%;
  }
}

.node-teaser .method_details .title a,
.view .method_details .title a {
  font-size: 18px;
  line-height: 30px;
  margin: 0;
}

.node-teaser .method_details .title p.resource_path,
.view .method_details .title p.resource_path {
  margin: 0;
  padding: 5px 0 0 0;
  color: #696969;
}

.node-teaser .method_details .verb-auth,
.view .method_details .verb-auth {
  width: 110px;
}

.node-teaser .method_details .title-description,
.view .method_details .title-description {
  padding: 0;
  float: none;
}

.node-teaser .method_details .description,
.view .method_details .description {
  line-height: 1.5;
  overflow: hidden;
  margin: 0;
  flex: 1;
}

@media screen and (max-width: 1024px) {
  .node-teaser .method_details .description,
  .view .method_details .description {
    width: 100%;
  }
}

.view-display-id-modelindex .views-row-first .method_details {
  border-top: 1px solid #A7A9AC;
}

.node-teaser span.icon_lock,
.view span.icon_lock {
  margin: 8px 4px 4px 10px;
}

.node-teaser h3.method-tag-header,
.view h3.method-tag-header {
  margin-top: 0;
}

.node-teaser h3.method-tag-header + .method_details,
.view h3.method-tag-header + .method_details {
  display: none;
}

.node-teaser h3:empty,
.view h3:empty {
  display: none;
}

#working_alert {
  background-color: rgba(255, 255, 255, 0.1);
  background-repeat: no-repeat;
  background-image: url(../images/spinner-gears.svg);
  border: 0 none;
  color: #333;
  text-shadow: none;
  z-index: 9999;
  width: 100%;
  position: absolute;
  top: 0;
  display: none;
  left: 0 !important;
  height: 100%;
  background-size: 200px;
  background-position: center;
  opacity: 0.9;
}

#working_alert p {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
  width: 1px;
  padding: 0;
  word-wrap: normal;
  font: 0/0 a;
}

/*# sourceMappingURL=acc-smartdocs.css.map */